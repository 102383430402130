import api from "../../api/api";
import { PROJECTS_PER_PAGE } from "../../utils/mainHelper";

const projectService = {
  createProject: (data) => api.post("/projects", data),
  getProjects: (page = 1, perPage = PROJECTS_PER_PAGE) =>
    api.get(`/projects?page=${page}&per_page=${perPage}`),
  getProjectById: (id) => api.get(`/projects/${id}`),
  updateProject: (id, data) => api.put(`/projects/${id}`, data),
  deleteProject: (id) => api.delete(`/projects/${id}`),
  trackProjectOpened: (id) => api.post(`/projects/${id}/track_opened_at`),
  duplicateProject: (id) => api.post(`/projects/${id}/duplicate`),
  updateProjectDetails: (id, data) =>
    api.put(`/projects/${id}/update_project_details`, data),
};

export default projectService;
