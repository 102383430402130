export const notifications = {
  authenticationError: "Please sign in again to continue",
  authorizationError: "You are not allowed to access the requested resource",
  notFound: "Requested resource not found",
  unexpectedError: "An unexpected error occurred",
  subscriptionCancelled: "Subscription cancelled successfully",
  somethingWentWrong: "Something went wrong. Please try again later!",
  cancelSubscriptionPending: "Cancel subscription pending, please wait...",
  projectUpdatedSuccessfully: "Project updated successfully",
};
