import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import planService from '../services/planService';
import { STATUSES } from '../../constants/statuses';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { notifications } from "../../utils/notificationConstants";

export const createCheckoutSession = createAsyncThunk(
  "plan/createCheckoutSession",
  async (data, thunkAPI) => {
    try {
      const response = await planService.createCheckoutSession(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserSubscriptions = createAsyncThunk(
  "plan/getUserSubscriptions",
  async ({ page, perPage }, thunkAPI) => {
    try {
      const response = await planService.getUserSubscriptions(page, perPage);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const cancelUserSubscription = createAsyncThunk(
  "plan/cancelUserSubscription",
  async (_, thunkAPI) => {
    try {
      const response = await planService.cancelUserSubscription();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const planSlice = createSlice({
  name: "plan",
  initialState: {
    subscriptions: [],
    sessionId: null,
    status: STATUSES.IDLE,
    error: null,
    loading: false,
    currentPage: 1,
    totalPages: 1,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.sessionId = action.payload.id;
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(createCheckoutSession.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = notifications.somethingWentWrong;
        showAllNotifications(state.error, ToastColors.error);
      })
      .addCase(getUserSubscriptions.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload.subscriptions;
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.total_pages;
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(getUserSubscriptions.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = notifications.somethingWentWrong;
      })
      .addCase(cancelUserSubscription.pending, (state) => {
        showAllNotifications(notifications.cancelSubscriptionPending);
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(cancelUserSubscription.fulfilled, (state, action) => {
        showAllNotifications(
          notifications.subscriptionCancelled,
          ToastColors.success
        );
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(cancelUserSubscription.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = notifications.somethingWentWrong;
      });
  },
});

export default planSlice.reducer;
