import React, { useState } from "react";
import { CrossIcon, DeleteUserIcon } from "../../assets/icons/icons";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { cancelUserSubscription } from "../../redux/slices/planSlice";

const CancelSubscriptionConfirmationModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const cancelSubscription = () => {
    setIsLoading(true);
    dispatch(cancelUserSubscription())
      .unwrap()
      .then(() => {
        setIsLoading(false);
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pb-[300px]">
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button onClick={onClose} className="absolute top-4 right-4">
          <img src={CrossIcon} alt="Close" />
        </button>

        <h2 className="text-[18px] font-[600] leading-[30px] text-[#18181B] mb-4">
          Cancel Subscription
        </h2>

        <div className="flex flex-col items-center justify-center text-center space-y-4">
          <div>
            <img src={DeleteUserIcon} alt="Delete Icon" />
          </div>

          <p className="font-inter text-[14px] font-semibold leading-[20px] text-[#18181B]">
            You are about to cancel your subscription
          </p>

          <p className="font-inter text-[14px] font-normal leading-[20px]">
            This wil cancel your subscription permanently.
          </p>

          <p className="font-inter text-[14px] font-normal leading-[20px] text-center">
            Are you sure?
          </p>
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6">
          <button
            onClick={onClose}
            className="bg-[#71717A] text-white px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={cancelSubscription}
            className="bg-[#18181B] text-white px-4 py-2 rounded-md"
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionConfirmationModal;
