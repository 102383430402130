import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../services/authService';
import { STATUSES } from '../../constants/statuses';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { getWithExpiry, setWithExpiry } from '../../utils/authHelper';

export const login = createAsyncThunk('auth/login', async ({ data, navigate }, thunkAPI) => {
  try {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    const response = await authService.login(data);
    setWithExpiry('access', response.data.access_token, 3600000);
    setWithExpiry('refresh', response.data.refresh_token, 3600000);
    return { ...response.data, navigate };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
//   try {
//     const response = await authService.logout();
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.response.data);
//   }
// });

export const handleSignup = createAsyncThunk('auth/handleSignup', async ({ data, navigate }, thunkAPI) => {
  try {
    const response = await authService.register(data);
    return { ...response.data, email: data.user.email, navigate };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const handleResetPassword = createAsyncThunk('auth/handleResetPassword', async (data, thunkAPI) => {
  try {
    const response = await authService.resetPassword(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const handleNewPassword = createAsyncThunk('auth/handleNewPassword', async ({ data, navigate }, thunkAPI) => {
  try {
    const response = await authService.newPassword(data);
    return { ...response.data, navigate };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const checkAuthToken = createAsyncThunk('auth/checkAuthToken', async (_, thunkAPI) => {
  const token = getWithExpiry('refresh');
  if (token) {
    try {
      const response = await authService.refreshToken(token);
      setWithExpiry('access', response.data.access_token, 3600000);
      return response.data;
    } catch (error) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return thunkAPI.rejectWithValue(error.response.data);
    }
  } else {
    return thunkAPI.rejectWithValue('No token found');
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    userRoles: [],
    isAuthenticated: false,
    isSubscribed: false,
    status: STATUSES.IDLE,
    error: null,
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = action.payload != null;
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.isSubscribed = false;
      state.isAuthenticated = false;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      showAllNotifications("Logout successfully", ToastColors.success);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.userRoles = action.payload.roles;
        state.isSubscribed = action.payload.subscribed;
        action.payload.navigate("/");
        showAllNotifications("Logged in successfully", ToastColors.success);
      })
      .addCase(login.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = "Invalid / Insufficient credentials";
        showAllNotifications(state.error, ToastColors.error);
      })
      // .addCase(logout.pending, (state) => {
      //   state.status = STATUSES.LOADING;
      //   state.error = null;
      //   state.loading = true;
      // })
      // .addCase(logout.fulfilled, (state) => {
      //   state.user = null;
      //   state.isAuthenticated = false;
      //   state.isSubscribed = false;
      //   state.status = STATUSES.IDLE;
      //   state.error = null;
      //   state.loading = false;
      //   localStorage.removeItem('access');
      //   localStorage.removeItem('refresh');
      //   showAllNotifications("Logout successfully", ToastColors.success);
      // })
      // .addCase(logout.rejected, (state) => {
      //   state.status = STATUSES.ERROR;
      //   state.loading = false;
      //   state.error = 'Something went wrong. Please try again!';
      //   showAllNotifications(state.error, ToastColors.error)
      // })
      .addCase(handleSignup.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleSignup.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        action.payload.navigate("/verify-email", {
          state: { email: action.payload.email, type: "register" },
        });
        showAllNotifications(
          "A token has been sent to your email!",
          ToastColors.success
        );
      })
      .addCase(handleSignup.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        showAllNotifications(action.payload, ToastColors.error);
      })
      .addCase(handleResetPassword.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleResetPassword.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        showAllNotifications(
          "Password reset instructions sent. Please check your email.",
          ToastColors.success
        );
      })
      .addCase(handleResetPassword.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        showAllNotifications(action.payload, ToastColors.error);
      })
      .addCase(handleNewPassword.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleNewPassword.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        action.payload.navigate("/signin");
        showAllNotifications(
          "Password reset successfully!",
          ToastColors.success
        );
      })
      .addCase(handleNewPassword.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        showAllNotifications("Failed to reset password!", ToastColors.error);
      })
      .addCase(checkAuthToken.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(checkAuthToken.fulfilled, (state, action) => {
        state.userRoles = action.payload.roles;
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.isSubscribed = action.payload.subscribed;
        state.status = STATUSES.IDLE;
        state.error = null;
      })
      .addCase(checkAuthToken.rejected, (state) => {
        state.user = null;
        state.isAuthenticated = false;
        state.status = STATUSES.ERROR;
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
      });
  },
});

export const { setUser, setIsSubscribed, logout } = authSlice.actions;

export default authSlice.reducer;
