import api from "../../api/api";

const planService = {
  createCheckoutSession: (data) => api.post("/create-checkout-session", data),
  getUserSubscriptions: (page, perPage) =>
    api.get(`/subscriptions?page=${page}&per_page=${perPage}`),
  cancelUserSubscription: () => api.delete("/cancel-subscription"),
};

export default planService;
