import React, { useEffect, useRef, useState } from "react";
import { projects } from "../../utils/projects/projectConstants";
import { ProjectOptions } from "../../assets/icons/icons";
import { timeAgo } from "../../utils/timeAgo";
import { useSelector } from "react-redux";

const ProjectListView = ({
  data,
  handleOpenProject,
  handleOpenRenameModal,
  handleOpenDeleteModal,
  duplicateProject,
}) => {
  const [activeProject, setActiveProject] = useState(null);
  const optionsRef = useRef(null);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        setActiveProject(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionsClick = (e, projectId) => {
    e.stopPropagation();
    setActiveProject(activeProject === projectId ? null : projectId);
  };

  return (
    <div className="space-y-4">
      {data.map((project) => (
        <div
          onClick={() => handleOpenProject(project.id)}
          key={project.id}
          className="flex items-center border border-[#E4E4E7] rounded-lg shadow-md hover:shadow-lg"
        >
          {/* Project Image */}
          <img
            src={project?.project_image_url || projects[project.id % 6].image}
            alt={project.name}
            className="max-w-[200px] max-h-[200px] object-cover p-3"
          />
          {/* Project Details */}
          <div className="flex flex-col flex-grow p-4">
            <div className="flex justify-between items-start relative">
              <h3 className="font-inter text-lg font-semibold text-[#18181B]">
                {project.name}
              </h3>
              {/* Options Button */}
              <button
                className="text-[#71717A] hover:bg-gray-200 p-1 rounded"
                onClick={(e) => handleOptionsClick(e, project.id)}
              >
                <img src={ProjectOptions} alt="Project Options" />
              </button>

              {/* Dropdown Menu */}
              {activeProject === project.id && (
                <div
                  ref={optionsRef}
                  className="absolute right-0 mt-2 bg-white border border-[#E4E4E7] rounded-lg shadow-lg w-48 z-10"
                  style={{
                    top: 20, // Align the dropdown with the button
                    right: 20, // Position it directly to the right of the button
                    marginLeft: "8px", // Optional: adds space between the button and the dropdown
                  }}
                >
                  <ul className="text-sm text-[#18181B]">
                    <li className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer">
                      Open
                    </li>
                    {project.user_id === user.id && (
                      <>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            handleOpenRenameModal(e, project.id);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Rename
                        </li>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            handleOpenDeleteModal(e, project.id);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Delete
                        </li>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            duplicateProject(e, project);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Duplicate
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <p className="text-[#71717A] text-sm">340KB | pdf</p>
            <p className="text-[#71717A] text-sm">
              Opened {timeAgo(project.opened_at || project.updated_at)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectListView;
